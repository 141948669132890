// eslint-disable-next-line
import React, {useEffect, useState} from 'react';
import defaultParams from './paramsConfig.json';
import {BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";
import { LogoutOutlined } from '@ant-design/icons';
import TableForm from './Components/TableForm';
import FormModal from "./Components/FormModal";
import { AuthorizationForm, OAuthCallback } from './Components/Auth';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import enEn from 'antd/lib/locale-provider/en_US';
import { ConfigProvider, Badge, Layout, Menu, Table, Select, Typography, Alert, Button, Tooltip, Radio } from 'antd';
import i18n from "i18next";
import { initReactI18next, useTranslation, withTranslation } from "react-i18next";
import {translation} from './translation';
import * as pkg from '../package.json';
import 'antd/dist/antd.css';
import './App.css';

const {Header, Content, Footer} = Layout;
const {Option} = Select;
const {Text} = Typography;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {translation: translation.commonEn},
      ru: {translation: translation.commonRu}
    },
    lng: "ru",
    fallbackLng: "ru",
    interpolation: { escapeValue: false }
  });

const TableContent = (props) => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [uiSchema, setUiSchema] = React.useState({});
    const [schema, setSchema] = React.useState({});
    const [jsonStructure, setJsonStructure] = React.useState({});
    const [uid, setUid] = React.useState("");
    const [routes] = React.useState(defaultParams);
    const [enumOptions, setEnumOptions] = React.useState({});
    const [existingOptions, setExistingOptions] = React.useState({});
    const [reference, setReference] = React.useState({});

    const fetchStock = async (signal) => {
        setReference({});

        const urlSchema = 'api/' + props.match.location.pathname.slice(1) + '/' + routes.getSchema;
        const urlUiSchema = 'api/' + props.match.location.pathname.slice(1) + '/' + routes.getUiSchema;

        fetch(urlSchema, {
            method: "GET",
        }, {signal: signal})
            .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        setIsLoaded(true)
                    }
                })
                    .then((data) => {
                        if (data) {
                            const receivedJsonStructure = (structure) => {
                                for (let key in data.properties) {
                                    structure[key] = undefined
                                }

                                return structure
                            };

                            const receivedUid = data.uid;

                            setTimeout(() => {
                                setSchema(data);
                                setJsonStructure(receivedJsonStructure({}));
                                setUid(receivedUid);
                                setIsLoaded(true);
                            }, 500)
                        }
                    })
                    .catch(error => {
                        if (error) {
                            props.handleError(error.message);
                            setIsLoaded(true)
                        }
                    });

                    fetch(urlUiSchema, {
                        method: "GET",
                    }, {signal: signal})
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            setIsLoaded(true)
                        }
                    })
                    .then(async (data) => {
                        if (data) {
                            const receivedUiSchema = {};
                            const enumItems = {};

                            Object.keys(data).forEach(i => {
                                if (data[i]["ui:widget"] === "customEnum") {
                                    receivedUiSchema[i] = {...data[i], "ui:widget": "customEnum"};
                                    
                                    enumItems[i] = schema?.properties?.[i]?.["reference"];
                                } else {
                                    if (Object.entries(data[i]).length !== 0) {
                                        receivedUiSchema[i] = data[i];
                                    }
                                }
                            });

                            setTimeout(() => {
                                setReference(enumItems);
                                setUiSchema(receivedUiSchema);
                                setIsLoaded(true);
                            }, 500)
                        }
                    })
    };

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        fetchStock(signal);

        return function cleanup() {
            abortController.abort()
        }
        // eslint-disable-next-line
    }, [routes, isLoaded]);

    const getEnumOptions = () => {
        const receivedOptionsArray = {};

        Object.keys(reference).forEach(async (i) => {
            if (uiSchema[i]?.["ui:widget"] === "customEnum" && reference[i]) {
                await fetch('api/' + (reference[i].table + "/get_data"), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            props.handleError(response.statusText, response.status);
                        }
                    })
                    .then(data => {
                        if (data && reference[i]) {
                            const receivedOptions = data.map(item => {
                                const id = item[reference[i].key];
                                const value = item[reference[i].value];

                                return <Option value={id} key={id + Math.random()}>
                                    <Text code>
                                        {id}
                                    </Text>
                                    {value}
                                </Option>
                            });
                            
                            receivedOptionsArray['root_' + i] = receivedOptions;
                        }
                    })
                    .catch(error => {
                        if (error) {
                            props.handleError(error.message);
                            setIsLoaded(true)
                        }
                    })
                }
        });

        setEnumOptions(receivedOptionsArray)
    };

    useEffect(() => {
        if (Object.entries(reference).length && Object.entries(schema).length) {
            getEnumOptions();
        } else {
            setEnumOptions({});
        }
        // eslint-disable-next-line
    }, [schema, reference]);

    const handler = () => {
        setIsLoaded(false);
    };

    const handleExistingOptions = (options) => {
        setExistingOptions(options)
    };

    if (isLoaded) {
        return (
            <Content style={{height: 300, padding: '0 50px', margin: '30px 0', verticalAlign: 'center'}}>
                <FormModal handler={handler}
                           role={props.role}
                           enumOptions={enumOptions}
                           existingOptions={existingOptions}
                           URL={URL}
                           project={props.project}
                           content={props.match.location.pathname.slice(1)}
                           uiSchema={uiSchema}
                           routes={routes}
                           schema={schema}
                           jsonStructure={jsonStructure}
                           uid={uid}/>
                <TableForm routes={routes}
                           role={props.role}
                           handleError={props.handleError}
                           handleExistingOptions={handleExistingOptions}
                           enumOptions={enumOptions}
                           URL={URL}
                           project={props.project}
                           content={props.match.location.pathname.slice(1)}
                           uiSchema={uiSchema}
                           schema={schema}
                           uid={uid}/>
            </Content>
        )
    } else {
        return (
            <Content style={{padding: '0 50px', margin: '30px 0', verticalAlign: 'center'}}>
                <Table loading={true}/>
            </Content>
        )
    }
}

const setUser = (userId, role) => {
    sessionStorage.setItem('user_id', userId);
    sessionStorage.setItem('user_role', role);
};

const isLoggedIn = () => {
    if (sessionStorage.getItem('is_logged_in') === 'true' && sessionStorage.getItem('is_logged_in') !== null) {
      return true
    } else {
      return false
    }
};

const LangSelect = () => {
    const {t} = useTranslation();

    const [lang, setLang] = useState("ru");

    const changeLang = (val) => {
      setLang(val)
    };
  
    useEffect(() => {
      i18n.changeLanguage(lang);
    }, [lang]);

    return (
        <Tooltip title={t('common.lang')}>
            <Radio.Group size="small" style={{width: 80, marginTop: 20, marginRight: 10}} onChange={(e) => changeLang(e.target.value)} value={lang} buttonStyle="solid">
                <Radio.Button value="ru">RU</Radio.Button>
                <Radio.Button value="en">EN</Radio.Button>
            </Radio.Group>
        </Tooltip>
    )
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem:  window.location.pathname.slice(1),
            project: "",
            projectContent: [],
            flag: false,
            loggedIn: isLoggedIn(),
            error: {
                open: false,
                message: '',
                description: ''
            }
        }
    };

    componentDidMount() {
        this.state.loggedIn && this.getTables() 
    };

    componentDidUpdate(prevState) {
        if (prevState.selectedItem !== this.state.selectedItem) {
            this.state.loggedIn && this.getTables()
        }
    };

    selectTableHandler = (item) => {
        this.setState({
            selectedItem: item
        });

        sessionStorage.setItem('currentPage', 1);
    };

    logIn = () => {
        sessionStorage.setItem('is_logged_in', 'true');
        this.setState({loggedIn: true})
    };
    
    logOut = () => {
        fetch(`oauth_logout`, {
            method: "GET",
            mode: 'no-cors',
          })
	    .then(res => {
	      sessionStorage.setItem('is_logged_in', 'false');

              setUser(null, null);

              this.setState({loggedIn: false})

              window.location = window.location.origin + process.env.PUBLIC_URL + '/log_in'
              })
            .catch(error => {
              console.log(`Login error: ${error}`);
              });
    };

    getTables = () => {
        if (this.state.flag === false) {
            fetch('api/' + defaultParams.config,
                {
                    method: 'GET',
                })
                .then(response => {
                    if (response.status === 401) this.logOut()

                    if (response.ok) {
                        return response.json()
                    } else {
                        this.handleError(response.statusText, response.status)
                    }
                })
                .then(data => {
                    if (data) {
                        this.setState({
                            project: this.state.project,
                            projectContent: Object.keys(data),
                            flag: true
                        })
                    }
                })
                .catch(error => {
                    if (error) {
                        this.handleError(error.message)
                    }
                })
        }
    };

    handleError = (description = '', message = '') => {
        this.setState({
            error: {
                open: true,
                message: 'Error ' + message,
                description
            }
        })

        setTimeout(this.onErrorClose, 3000)
    };

    onErrorClose = () => {
        this.setState({
            error: {
                open: false,
                message: "",
                description: ""
            }
        })
    };

    render() {
        return (
            <Router basename={`${process.env.PUBLIC_URL}`}>
                    {this.state.error.open && <Alert message={this.state.error.message}
                                                     description={this.state.error.description}
                                                     type="error"
                                                     closable
                                                     onClose={this.onErrorClose}
                    />}
                            {<Layout style={{height: "100vh"}}
                                    className="layout">
                                {this.state.loggedIn && <Header style={{display: 'flex', position: 'sticky', top: 0, zIndex: 999}}>
                                    <Menu theme="dark"
                                        style={{width: '100%'}}
                                        mode="horizontal"
                                        selectedKeys={[this.state.selectedItem]}
                                        onClick={({key}) => this.selectTableHandler(key)}>
                                        {this.state.projectContent.map(item => {
                                            return (
                                                <Menu.Item key={item}>
                                                    <span>{item}</span>
                                                    <Link to={item}/>
                                                </Menu.Item>
                                            )
                                        })}
                                    </Menu>
                                    <LangSelect />
                                    <Tooltip title={this.props.t(`common.logout`)}>
                                        <Button className={"logout-button-style"}
                                                type="primary"
                                                shape="round"
                                                icon={<LogoutOutlined/>}
                                                onClick={this.logOut} />
                                    </Tooltip>
                                    <Badge style={{marginTop: 5, backgroundColor: '#001529'}} count={pkg.version} />
                                </Header>}
                                <Content style={{height: '100vh'}}>
                                    {!this.state.loggedIn &&
                                        <Route key={'log_in'}
                                            exact path={"/log_in"}
                                            render={(match) =>
                                            <AuthorizationForm handleError={this.handleError}
                                                               setUser={setUser}
                                                               logIn={this.logIn}/>
                                        } />
                                    }
                                    {!this.state.loggedIn && <Route key="null" exact path='/nb_cms_callback'>
                                        <OAuthCallback handleError={this.handleError} logIn={this.logIn} setUser={setUser}/>
                                    </Route>}
                                    {!this.state.loggedIn && window.location.pathname !== '/nb_cms_callback' && <Route key="null" path='/*'>
                                        <Redirect to={'log_in'}/>
                                    </Route>}
                                    {this.state.loggedIn && <Route key="null" path='/*'>
                                        <Redirect to={(!!this.state.projectContent.find(i => i === this.state.selectedItem)? this.state.selectedItem: this.state.projectContent[0]) || ''}/>
                                    </Route>}
                                    {this.state.loggedIn && this.state.projectContent.map(item => {
                                        return (
                                            <Route key={item}
                                                path={"/" + item}
                                                render={(match) =>
                                                    <ConfigProvider locale={this.props.i18n.language === 'en'? enEn: ruRU}>
                                                        <TableContent   match={match}
                                                                        role={sessionStorage.getItem('user_role')}
                                                                        handleError={this.handleError}
                                                                        project={this.state.project}/>
                                                    </ConfigProvider>
                                                } />
                                        )
                                    })}
                                </Content>
                                <Footer style={{textAlign: "center"}}>
                                    <Text align="center">
                                        {'Copyright © BSS '}
                                        {new Date().getFullYear()}
                                    </Text>
                                </Footer>
                            </Layout>}
            </Router>
        )
    }
};

export default withTranslation()(App);
