import {Button, Select, Typography} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const {Option} = Select;
const {Text} = Typography;

const CustomEnum = (props) => {
    const {t} = useTranslation();
    
    return (
        <Select showSearch
                value={props.value}
                style={{ width: '100%' }}
                placeholder={`${t('common.select')} ${props.label.toLowerCase()}`}
                onChange={value => {
                    props.onChange(value)
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children[option.children.length - 1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
            {props.schema.format !== 'audio' && props.formContext.uid !== props.schema.content? <Option value={''} key={'null_option'}><Text code>{'null'}</Text>{''}</Option>: null}
            {!props.formContext.existingOptions? props.formContext.enumOptions?.[props.id]: props.formContext.enumOptions?.[props.id]?.filter(item => !props.formContext.existingOptions.includes(item.props.value))}
        </Select>
    )
}

const CustomUpload = (props) => {
    const {t} = useTranslation();
    
    const [name, setName] = useState(props.value || '');

    useEffect(() => {
        props.onChange(name);
        // eslint-disable-next-line
    }, [props.formContext.files]);

    return (
        <>
            <input name="file"
                id="file"
                type="file"
                hidden
                onChange={(e) => {
                        if (e.target?.files) {
                            setName(e.target?.files[0].name);
                            props.formContext.setFiles({...props.formContext.files, [props.schema.content]: e.target?.files[0]});
                        }
                }}
                accept={'.wav'}
            />
            <label style={{cursor: "pointer"}} htmlFor="file">
                <Button style={{marginRight: 10, pointerEvents: "none"}} type="primary">
                    {t(`common.upload`)}
                </Button>
            </label>
            {name}
        </>
    )
}

export {CustomEnum, CustomUpload};