import React from 'react';
import '../App.css';
import adminRights from '../adminRights.json';
import { Modal, Button, Tooltip } from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class DeleteModal extends React.Component {
    state = {
        ModalText: 'Content of the modal',
        visible: false,
        confirmLoading: false,
        confirmation: false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = async () => {
        const url = 'api/' + this.props.content + '/' + this.props.routes.deleteData;
        const body = {uid: this.props.itemId};

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Basic " + this.props.basicAuthToken
            }),
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response;
                } else {
                    this.props.handleError(response.statusText, response.status)
                }
            })
            .then(data => {
                this.props.handler()
            })
            .catch(error => {
                if (error) {
                    this.props.handleError(error.message)
                }
            })
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const {visible, confirmLoading, confirmation} = this.state;

        if (confirmation === false) {
            return (
                <div>
                    <Tooltip title={this.props.t(`common.delete`)}>
                        <Button disabled={!adminRights.includes(this.props.role)} shape="circle" onClick={this.showModal} icon={<DeleteOutlined/>}/>
                    </Tooltip>
                    <Modal
                        title={this.props.t('common.deletion')}
                        width={700}
                        open={visible}
                        confirmLoading={confirmLoading}
                        onCancel={this.handleCancel}
                        footer={null}>
                        <p> <ExclamationCircleOutlined /> {this.props.t(`questions.delete`)}</p>
                        <div className={"submit-button-block"}>
                            <Button
                                onClick={this.handleCancel}>
                                {this.props.t(`common.cancel`)}
                            </Button>
                            <Button
                                onClick={this.handleOk}
                                type="primary">
                                {this.props.t(`common.delete`)}
                            </Button>
                        </div>
                    </Modal>
                </div>
            )
        }
    }
};

export default withTranslation()(DeleteModal);
