import React from "react";
import {Button} from 'antd';
import {PlusOutlined  , CloseOutlined } from '@ant-design/icons';

const ArrayFieldTemplate = (props) => {
    return (
        <div>
            <div style={{display: 'flex', width: '100%', margin: '0 0 10px 0'}}>
                <label style={{display: 'flex', alignItems: 'left', color: 'rgba(0, 0, 0, 0.85)'}} className='ant-form-item-label'>{props.schema.title}</label>
                {props.canAdd && <Button style={{margin: '0 0 0 15px'}} icon={<PlusOutlined />} onClick={props.onAddClick}>Add item</Button>}
            </div>
            <div className={props.className}>
                {props.items && props.items.map((element) => (
                    <div key={element.index} className='custom-array-input' style={{display: 'flex', width: '100%'}}>
                        {element.children}
                        <Button style={{margin: '0 0 0 8px', width: '10%'}} icon={<CloseOutlined />} onClick={element.onDropIndexClick(element.index)}></Button>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ArrayFieldTemplate;