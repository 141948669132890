import React from 'react';
import {Modal, Button, Tooltip} from 'antd';
import {withTheme} from '@rjsf/core';
import {Theme as AntDTheme} from '@rjsf/antd';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import ArrayFieldTemplate from "./ArrayFieldTemplate";
import {CustomEnum, CustomUpload} from "./CustomComponents";
import adminRights from '../adminRights.json';
import { Alert } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import validator from '@rjsf/validator-ajv8';
import '../App.css';
import moment from 'moment';

const Form = withTheme(AntDTheme);

const CustomForm = (props) => {
    const {t} = useTranslation();

    const [formData, setFormData] = React.useState(props.item);
    const [error, setError] = React.useState(true);
    const [files, setFiles] = React.useState({});

    const uiSchema = {...props.uiSchema, [props.uid]: {...props.uiSchema[props.uid], "ui:disabled": props.item[props.uid]}};

    const widgets = {
        customEnum: CustomEnum,
        customUpload: CustomUpload
    };

    function transformErrors(errors) {
        return errors.map((error) => {
          const prop = error.property.slice(1);

          if (error.name === 'pattern') error.message = uiSchema[prop]?.errorPattern || error.stack;
          if (error.name === 'required') error.message = uiSchema[prop]?.errorRequired || t('errors.field_required');

          return error;
        });
      }

    return (
        <Form ArrayFieldTemplate={ArrayFieldTemplate}
              validator={validator}
              transformErrors={transformErrors}
              widgets={widgets}
              formContext={{'files': files,'setFiles': setFiles, 'enumOptions': props.enumOptions, 'formData': formData, 'uid': props.uid, 'existingOptions': props.existingOptions }}
              schema={props.schema}
              uiSchema={uiSchema}
              formData={formData}
              liveValidate
              showErrorList={false}
              className="modal-content-style"
              onChange={(e) => {
                  setFormData(e.formData)

                  if (e.errors.length === 0) {
                      setError(false)
                  } else {
                      setError(true)
                  }
              }}>
            <div className={"submit-button-block"}>
                <Button onClick={props.onCancel}>
                    {t(`common.cancel`)}
                </Button>
                <Button type="primary"
                        htmlType="submit"
                        onClick={() => {
                            if (error === false) {
                                const data = {};

                                for (let key in props.schema.properties) {
                                    if (props.schema.properties[key]['type'] === 'array') {
                                        if (!formData[key]) {
                                            data[key] = []
                                        } else {
                                            data[key] = formData[key]
                                        }
                                    } else if (props.schema.properties[key]['type'] === 'string') {
                                        if (!formData[key]) {
                                            if (props.schema.properties[key]['format'] === 'date-time') {
                                                data[key] = moment().unix().toString()
                                            } else {
                                                data[key] = ''
                                            }
                                        } else {
                                            if (props.schema.properties[key]['format'] === 'date-time') {
                                                data[key] = moment(formData[key]).unix().toString()
                                            } else {
                                                data[key] = formData[key]
                                            }
                                        }

                                        if (props.schema.properties[key]['format'] === 'data') {
                                            if (!formData[key]) {
                                                if (props.schema['required'].includes(key)) {
                                                    props.setFormErrorMessage(t('errors.file_req'))

                                                    return
                                                }
                                            } else {
                                                data[key] = files[key].name
                                            }
                                        }
                                    } else if (props.schema.properties[key]['type'] === 'number') {
                                        if (!formData[key]) {
                                            data[key] = 0
                                        } else {
                                            data[key] = formData[key]
                                        }
                                    } else if (props.schema.properties[key]['type'] === 'boolean') {
                                        if (!formData[key]) {
                                            data[key] = false
                                        } else {
                                            data[key] = formData[key]
                                        }
                                    }
                                };

                                if (props.schema.validation_rules?.some(rule => {
                                    if (rule.type === 'less') {
                                        if (data[rule.arg1] > data[rule.arg2]) {
                                            const arg1_title = props.schema.properties[rule.arg1].title;
                                            const arg2_title = props.schema.properties[rule.arg2].title;

                                            props.setFormErrorMessage(t('errors.less', {arg1: arg1_title, arg2: arg2_title}));

                                            return true
                                        }
                                    }
                                })) {
                                    
                                } else {
                                    props.onAdd(data, files)
                                }
                            }
                        }}>
                    {t(`common.ok`)}
                </Button>
            </div>
        </Form>
    );
};

class FormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            duplicateWarning: false,
            currentFormData: this.props.jsonStructure,
            prevValue: '',
            formErrorMessage: ''
        }
    };

    setFormErrorMessage = (error) => {
        this.setState({
            formErrorMessage: error,
        })
    };

    onEdit = async (item, files) => {
        const url = 'api/' + this.props.content;
        const body = item;

        await fetch(url + '/' + this.props.routes.setData, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    this.props.handler();
                    this.setState({visible: false});
                } else {
                    this.setFormErrorMessage(response.statusText, response.status);
                }
            })
            .catch(error => {
                if (error) {
                    this.setFormErrorMessage(error)
                }
            });

        if (Object.entries(files).length) {
            for (const key in files) {
                if (files[key]) {
                    const fd = new FormData();

                    fd.append('file', new Blob([files[key]], {type:"multipart/form-data"}), files[key].name);
                
                    await fetch(url + '/' + item[this.props.uid] + '/' + key + '/' + this.props.routes.setvData, {
                        method: "POST",
                        body: fd
                    })
                    .then(response => {
                        if (response.ok) {
                            this.props.handler();
                            this.setState({visible: false});
                        } else {
                            this.setFormErrorMessage(response.statusText, response.status);
                        }
                    })
                    .catch(error => {
                        if (error) {
                            this.setFormErrorMessage(error)
                        }
                    });
                }
            }
        }
    };

    onAdd = async (item, files) => {
        const url = 'api/' + this.props.content;
        const body = item

        await fetch(url + '/' + this.props.routes.addData, {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(body)
            })
            .then(response => {
                if (response.status === 200) {
                    for (const key in files) {
                        if (files[key]) {
                            const fd = new FormData();
            
                            fd.append('file', new Blob([files[key]], {type:"multipart/form-data"}), files[key].name);
                        
                            fetch(url + '/' + item[this.props.uid] + '/' + key + '/' + this.props.routes.setvData, {
                                method: "POST",
                                body: fd
                            })
                            .then(response => {
                                if (response.ok) {
                                    this.props.handler();
                                    this.setState({visible: false});
                                } else if (response.status === 409) {
                                    this.setFormErrorMessage(response.statusText, response.status);
                                }
                            })
                            .catch(error => {
                                if (error) {
                                    this.setFormErrorMessage(error)
                                }
                            });
                        }
                    }

                    this.props.handler();
                    this.setState({visible: false});
                } else if (response.status === 409) {
                    this.setFormErrorMessage(this.props.t('errors.already_exists'), response.status);
                }
            })
            .catch(error => {
                if (error) {
                    this.setFormErrorMessage(error)
                }
            });
    };

    onCancel = () => {
        this.setState({
            visible: false,
            duplicateWarning: false,
            currentFormData: this.props.jsonStructure
        });

        this.setFormErrorMessage('')
    };

    onShowModal = () => {
        this.setState({
            visible: true
        })
    };

    render() {
        return (
            <div className={!this.props.item? 'add-modal-style': ''}>
                <Tooltip title={this.props.t(`common.${!this.props.item? 'add': 'edit'}`)}>
                    {!this.props.item?
                        <Button id={'create'}
                                type='primary'
                                disabled={!adminRights.includes(this.props.role)}
                                shape="round"
                                icon={<PlusOutlined/>}
                                size={"large"}
                                onClick={this.onShowModal}>
                        </Button>:
                        <Button onClick={this.onShowModal}
                                disabled={!adminRights.includes(this.props.role)}
                                icon={<EditOutlined/>}
                                shape="circle">
                        </Button>}
                </Tooltip>
                {this.state.visible &&
                    <Modal footer={null}
                        width={800}
                        open={this.state.visible}
                        title={this.props.t(`common.${this.props.item? "editing": "adding"}`)}
                        onCancel={this.onCancel}
                        destroyOnClose={true}>
                        {this.state.formErrorMessage && <Alert afterClose={() => this.setFormErrorMessage('')} closable message={this.state.formErrorMessage} type="error" />}
                        <CustomForm schema={this.props.schema}
                                setFormErrorMessage={this.setFormErrorMessage}
                                item={this.props.item || this.state.currentFormData}
                                content={this.props.content}
                                existingOptions={this.props.existingOptions}
                                enumOptions={this.props.enumOptions}
                                uiSchema={this.props.uiSchema}
                                uid={this.props.uid}
                                currentFormData={this.props.jsonStructure}
                                duplicateWarning={this.state.duplicateWarning}
                                onAdd={!this.props.item? this.onAdd: this.onEdit}
                                onCancel={this.onCancel}
                                prevValue={this.state.prevValue}/>
                    </Modal>}
            </div>
        )
    }
};

export default withTranslation()(FormModal);