import React, {useRef, useEffect} from 'react';
import DeleteModal from "./DeleteModal";
import FormModal from "./FormModal";
import {Table, Input, Button, Typography, Space, Tooltip} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import moment from 'moment';
import 'moment-timezone';

const TableForm = (props) => {
    const {t} = useTranslation();

    const [data, setData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [searchText, setSearchText] = React.useState({});
    const [searchedColumns, setSearchedColumns] = React.useState([]);
    const [page, setPage] = React.useState(Number(sessionStorage.getItem('currentPage')) || 1);
    const [playingId, setPlayingId] = React.useState(null);
    const [audioTimeOut, setAudioTimeOut] = React.useState(() => {}, 0);

    const searchInput = useRef(null);
    const audioRef = useRef(null);
    
    const timeKeys = (props.schema.properties && Object.values(props.schema.properties).filter(p => p.format === "date-time").map(p => p.content)) || [];

    const fetchStock = async (signal) => {
        const url = 'api/' + props.content + '/' + props.routes.getData;

        await fetch(url, {
            method: "GET"
        }, {signal: signal})
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    props.handleError(response.statusText, response.status);
                    setIsLoaded(true)
                }
            })
            .then(async data => {
                if (data) {
                    const receivedTableData = [...data].map(item => {
                        const newItem = {...item};

                        for (const key in item) {
                            if (props.uid && props.uid !== key && Array.isArray(item[key]) !== true && typeof item[key] !== 'number' && typeof item[key] !== 'boolean') {
                                if (item[key].includes('$')) newItem[key] = item[key]
                                                                        .replace(/[$]/g, '')
                                                                        .replace(/[{]/g, '<b style="color: #0066cc">')
                                                                        .replace(/[}]/g, '</b>');                                
                            };
    
                            if (timeKeys.includes(key)) newItem[key] = moment.unix(item[key])
                                                                        .utc()
                                                                        .tz('Europe/Moscow')
                                                                        .format("YYYY-MM-DD HH:mm:ss");
                        };

                        return newItem;
                    });

                    setTimeout(() => {
                        setData(data || []);
                        setTableData(receivedTableData.reverse() || []);
                        setIsLoaded(true);

                        props.handleExistingOptions(data?.reverse().map(item => item[props.uid]) || [])
                    }, 500)
                }
            })
            .catch(error => {
                if (error) {
                    props.handleError(error.message);
                    setIsLoaded(true)
                }
            })
    };

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        if (!isLoaded && props.schema?.properties) {

            fetchStock(signal);

            return function cleanup() {
                abortController.abort();
            };
        };
        // eslint-disable-next-line
    }, [isLoaded, props.schema.properties]);

    useEffect(() => {
        if (playingId) {
            fetch(`api/${props.content}/${playingId}/audio/${props.routes.getvData}`, {
                method: "GET"
            })
            .then(response => {
                if (response.ok) {
                    return response.blob();
                };
            })
            .then(data => {
                if (data) {
                    const url = URL.createObjectURL(data);

                    audioRef.current.src = url;
                    audioRef.current.play()
                        .then(() => {
                            clearTimeout(audioTimeOut)
                            const duration = audioRef?.current?.duration;

                            setAudioTimeOut(setTimeout(() => {
                                setPlayingId(null)
                            }, duration*1000))
                        })
                } else {
                    props.handleError(t('errors.no_audio'));
                    
                    setPlayingId(null);
                }
            })
            .catch(error => {
                if (error) {
                    props.handleError(error.message);
                    setPlayingId(null);
                    setIsLoaded(true)
                }
            })
        }
        // eslint-disable-next-line
    }, [playingId]);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input ref={searchInput}
                       placeholder={`${t('common.search')}`}
                       value={selectedKeys[0]}
                       onChange={e => setSelectedKeys(e.target.value ? [e.target.value.toLowerCase()] : [])}
                       onBlur={() => { 
                        if (!searchedColumns.includes(dataIndex)) {
                            setTimeout(() => setSelectedKeys([]), 1000);
                        }
                       }}
                       onPressEnter={() => selectedKeys.length? handleSearch(selectedKeys, confirm, dataIndex): handleReset(clearFilters, confirm, dataIndex)}
                       style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button type="primary"
                            onClick={() => selectedKeys.length? handleSearch(selectedKeys, confirm, dataIndex): handleReset(clearFilters, confirm, dataIndex)}
                            icon={<SearchOutlined/>}
                            size="small"
                            style={{width: 90}}>
                        Поиск
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, confirm, dataIndex)} size="small" style={{width: 90}}>
                        {t('common.reset')}
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase() ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase().trim()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setSearchText({...searchText, [dataIndex]: [selectedKeys[0]]});
        setSearchedColumns([...searchedColumns, dataIndex]);
        confirm();
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        setSearchText({...searchText, [dataIndex]: []});
        setSearchedColumns(searchedColumns.filter(c => c !== dataIndex));
        confirm();
    };

    const handler = () => {
        setIsLoaded(false);
    };

    const onPlayingSet = (id) => {
        if (id !== playingId) {
            setPlayingId(id);
        } else {
            setPlayingId(null);

            return audioRef?.current?.pause();
        }
    };

    const columns = (arr = []) => {
        const uid = props.uid;

        arr.push({
            title: <span style={{backgroundColor: '#1890ff', color: 'white'}}>{`[ ${props.content} ]`}</span>,
            align: 'center',
            render: (text, record, index) => (page - 1) * 10 + index + 1
        });

        for (let itemKey in props.schema.properties) {
            if (props.uiSchema?.[itemKey]?.view === 'audio-player') {
                arr.push(
                    {
                        title: props.schema.properties[itemKey].title,
                        width: 50,
                        dataIndex: props.schema.properties[itemKey].content,
                        key: props.schema.properties[itemKey].content,
                        render: (item, row) => 
                            <Space size="middle">
                                <Tooltip title={item}>
                                    <Button shape='circle' onClick={() => onPlayingSet(row[props.uid])}>
                                        {playingId !== row[props.uid]? <FontAwesomeIcon icon={faPlay} />: <FontAwesomeIcon icon={faStop} />}
                                    </Button>
                                </Tooltip>
                            </Space>
                    }
                );
            } else {
                if (props.schema.properties[itemKey].type !== 'array' && props.schema.properties[itemKey].format !== 'audio') {
                    arr.push(
                        {
                            title: props.schema.properties[itemKey].title,
                            dataIndex: props.schema.properties[itemKey].content,
                            key: props.schema.properties[itemKey].content,
                            align: 'left',
                            ...getColumnSearchProps(props.schema.properties[itemKey].content, ),
                            sorter: {
                                compare: (a, b) => a[itemKey] || b[itemKey]? a[itemKey]?.toString().localeCompare(b[itemKey]?.toString(), 'en', {numeric: true}): null
                            },
                            render: (text) =>
                                    <Typography key={Math.random().toString()} dangerouslySetInnerHTML={{__html: 
                                        !searchedColumns.includes(props.schema.properties[itemKey].content)?
                                            text:
                                            text.toString().replace(searchText[props.schema.properties[itemKey].content]?.[0].toString(),
                                                        `<mark>${searchText[props.schema.properties[itemKey].content]?.[0].toString()}</mark>`)
                                    }}></Typography>
                        }
                    );
                } else if (props.schema.properties[itemKey].type === 'array') {
                    arr.push(
                        {
                            title: props.schema.properties[itemKey].title,
                            dataIndex: props.schema.properties[itemKey].content,
                            key: props.schema.properties[itemKey].content,
                            width: 300,
                            align: 'left',
                            render: (tags) =>
                                <>
                                    {tags.map((tag, index) => {
                                        if (index !== tags.length - 1) {
                                            return (
                                                <span key={Math.random().toString()}>
                                                    {tag + ', '}
                                                </span>
                                            )
                                        } else {
                                            return (
                                                <span key={Math.random().toString()}>
                                                    {tag}
                                                </span>
                                            )
                                        }
                                    })}
                                </>
                        }
                    )
                }
            }
        };

        const origRow = (row) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i][props.uid] === row[props.uid]) {
                    const timeObj = timeKeys.reduce((a, k) => ({ ...a, [k]: moment(tableData[i][k]).tz('Europe/Moscow').format("YYYY-MM-DDTHH:mm:ss.000Z")}), {});

                    return {...data[i], ...timeObj}
                };
            };
        };

        arr.push(
            {
                title: t('table.actions'),
                dataIndex: 'action',
                key: 'action',
                width: 125,
                fixed: 'right',
                align: 'center',
                render: (text, row) =>
                    <Space size="middle">
                        <FormModal item={origRow(row)}
                                   role={props.role}
                                   basicAuthToken={sessionStorage.getItem('auth_token')}
                                   handleError={props.handleError}
                                   enumOptions={props.enumOptions}
                                   URL={props.URL}
                                   isLoaded={isLoaded}
                                   project={props.project}
                                   content={props.content}
                                   uiSchema={props.uiSchema}
                                   routes={props.routes}
                                   uid={uid}
                                   handler={handler}
                                   schema={props.schema}
                        />
                        <DeleteModal itemId={row[uid]}
                                     role={props.role}
                                     isLoaded={isLoaded}
                                     basicAuthToken={sessionStorage.getItem('auth_token')}
                                     enumOptions={props.enumOptions}
                                     URL={props.URL}
                                     project={props.project}
                                     content={props.content}
                                     routes={props.routes}
                                     uid={uid}
                                     handler={handler}/>
                    </Space>
            }
        )

        return arr
    };

    if (isLoaded) {
        return (
            <>
                <audio style={{display: 'none'}} ref={audioRef}/>
                <Table  columns={columns()}
                        pagination={{
                            current: page,
                            onChange(current) {
                                setPage(current);
                                sessionStorage.setItem('currentPage', current)
                            }
                        }}
                        URL={props.URL}
                        dataSource={tableData}
                        scroll={{x: true, y: '72vh'}}
                        bordered
                        rowKey={props.uid} />
            </>
        );
    } else {
        return (
            <Table loading={true}/>
        );
    }
};

export default TableForm;
