export const translation = {
  commonRu: {
    common: {
      add: "Добавить",
      edit: "Редактировать",
      ok: "Ок",
      cancel: "Отмена",
      delete: "Удалить",
      select: "Выбрать",
      logout: "Выйти",
      search: "Искать",
      reset: "Сброс",
      deletion: "Удаление",
      adding: "Добавление",
      editing: "Редактирование",
      lang: "Сменить язык",
      upload: "Загрузить файл"
    },
    table: {
      actions: "Действия"
    },
    questions: {
      delete: "Вы уверены, что хотите удалить строку?"
    },
    errors: {
      wrong: "Что-то пошло не так",
      already_exists: "Уже существует",
      login: "Неверный логин или пароль",
      auth_server: "Нет сервера аутентификации",
      field_required: "Обязательное поле",
      less: "Значение '{{arg1}}' не меньше значения '{{arg2}}'",
      no_audio: "Нет аудио",
      file_req: "Файл является обязательным"
    }
  },
  commonEn: {
    common: {
      add: "Add",
      edit: "Edit",
      ok: "Ok",
      cancel: "Cancel",
      delete: "Delete",
      select: "Select",
      logout: "Logout",
      search: "Search",
      reset: "Reset",
      deletion: "Deletion",
      adding: "Adding",
      editing: "Editing",
      lang: "Change language",
      upload: "Upload file"
    },
    table: {
      actions: "Actions"
    },
    questions: {
      delete: "Are you sure you want to delete item?"
    },
    errors: {
      wrong: "Something went wrong",
      already_exists: "Already exists",
      login: "Wrong login or password",
      auth_server: "No auth server",
      field_required: "Is a required field",
      less: "'{{arg1}}' value is not less then '{{arg2}}' value",
      no_audio: "No audio",
      file_req: "File is required"
    }
  }
}
