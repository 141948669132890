import React, {useEffect, useState} from 'react';
import { Col, Row } from 'antd';
import { Button } from 'antd';
import { Avatar } from 'antd';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const validateUser = async (obj, setUser, setLoggedIn, handleError, t) => {
  await fetch('login', {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(obj)
  })
  .then(res => {
    if (res.ok === false) {
      handleError(t('error.login'))
    } else {
      return res.json();
    }
  })
  .then(data => {
    if (data) {
      setUser(data.user.login, data.user.role);

      setLoggedIn();
    }
  })
  .catch(err => {
    handleError(err.message)
  });
};

const LocalSignIn = (props) => {
  const {t} = useTranslation();

  const [loginFormData, setLoginFormData] = useState({
    login: '',
    password: ''
  });

  const validate = () => {
    if (loginFormData.login && loginFormData.password) {
      validateUser({"user": loginFormData}, props.setUser, props.logIn, props.handleError, t)
    }
  };

  // eslint-disable-next-line
  useEffect(() => validate(), []);

  return (
      <Row alignItems="center" style={{height: '100vh'}}>
        <Col style={{textAlign: "center", padding: 100}} span={24}>
          <Avatar style={{marginBottom: 30}} size={100} icon={<LockOutlined />}/>
          <form autoComplete={'off'}>
            <div style={{width: "100%"}}>
              <Input style={{width: "20%"}}
                        fullWidth
                        required
                        onChange={e => {
                          const obj = loginFormData;

                          obj.login = e.target.value;

                          setLoginFormData(obj)
                        }}
                        placeholder="User name"
                        margin="normal"
                        variant="outlined"/>
            </div>
            <div style={{width: "100%"}}>
              <Input style={{width: "20%"}}
                        fullWidth
                        required
                        type="password"
                        onChange={e => {
                          const obj = loginFormData;

                          obj.password = e.target.value;

                          setLoginFormData(obj)
                        }}
                        placeholder="Password"
                        margin="normal"
                        variant="outlined"/>
            </div>
            <Button style={{width: "10%", marginTop: 30}}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      validate()
                    }}>
              Sign in
            </Button>
          </form>
        </Col>
      </Row>
  )
};

export const OAuthCallback = (props) => {
  const {t} = useTranslation();

  useEffect(() => {
    fetch(`oauth_login${window.location.search}`, {
      method: "GET",
      mode: 'no-cors',
    })
      .then(res => {
        if (!res.ok) {
          props.handleError(t('errors.login'))
        } else {
          return res.json();
        }
      })
      .then(data => {
        if (data) {
          props.setUser(data.user.login, data.user.role);
    
          props.logIn();
        }
      })
      .catch(error => {
        console.log(`Login error: ${error}`);
      });
    // eslint-disable-next-line
  }, [props]);

  return <span>authorizing...</span>;
};

export const AuthorizationForm = (props) => {
  const {t} = useTranslation();

  const [oauth_server, setOauth_server] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetch('validate_user', {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      })
    })
    .then(res => {
      if (res.ok === false) {
        fetch('oauth_server', {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          })
        })
        .then(res => {
          if (res.ok === false) {
            props.handleError(t('errors.auth_server'))
          } else {
            return res.json();
          }
        })
        .then(data => {
          if (data) {
            setOauth_server(data.oauth_server);
          };
          
          setLoaded(true)
        })
        .catch(err => {
          props.handleError(err.message)
        });
      } else {
        return res.json();
      }
    })
    .then(data => {
      if (data) {
        props.setUser(data.user.login, data.user.role);
        setLoaded(true);

        props.logIn();
      }
    })
    .catch(err => {
      props.handleError(err.message)
    });
    // eslint-disable-next-line
  }, []);

  return loaded && (!oauth_server ? <LocalSignIn handleError={props.handleError} setUser={props.setUser} logIn={props.logIn}/> : window.location = `${oauth_server}`);
};
